export default [
  {
    path: '/master/compliance',
    name: 'pageMasterCompliance',
    component: () => import('@/views/master/ComplianceList'),
    meta: {
      pageTitle: 'Compliance',
      breadcrumb: [
        {
          text: 'Compliance',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/compliance/add',
    name: 'pageMasterComplianceAdd',
    component: () => import('@/views/master/ComplianceForm'),
    meta: {
      pageTitle: 'Compliance',
      navActiveLink: 'pageMasterCompliance',
      breadcrumb: [
        {
          text: 'Compliance',
          to: '/master/compliance'
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/compliance/edit/:id',
    name: 'pageMasterComplianceEdit',
    component: () => import('@/views/master/ComplianceForm'),
    meta: {
      pageTitle: 'Compliance',
      navActiveLink: 'pageMasterCompliance',
      breadcrumb: [
        {
          text: 'Compliance',
          to: '/master/compliance'
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      if (from.path === '/') next({ name: 'home' })
      else next()
    }
  },
]