export default [
  {
    path: '/master/attachment-type',
    name: 'pageMasterAttachmentType',
    component: () => import('@/views/master/AttachmentTypeList'),
    meta: {
      pageTitle: 'Attachment Type',
      breadcrumb: [
        {
          text: 'Attachment Type',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/attachment-type/add',
    name: 'pageMasterAttachmentTypeAdd',
    component: () => import('@/views/master/AttachmentTypeForm'),
    meta: {
      pageTitle: 'Attachment Type',
      navActiveLink: 'pageMasterAttachmentType',
      breadcrumb: [
        {
          text: 'Attachment Type',
          to: '/master/attachment-type'
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/attachment-type/edit/:id',
    name: 'pageMasterAttachmentTypeEdit',
    component: () => import('@/views/master/AttachmentTypeForm'),
    meta: {
      pageTitle: 'Attachment Type',
      navActiveLink: 'pageMasterAttachmentType',
      breadcrumb: [
        {
          text: 'Attachment Type',
          to: '/master/attachment-type'
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      if (from.path === '/') next({ name: 'home' })
      else next()
    }
  },
]