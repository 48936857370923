import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import master from './routes/master'
import page from './routes/page'

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {x: 0, y: 0}
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/calendar/Calendar'),
      meta: {
        pageTitle: 'Home',
        rule: 'home',
        // parent: 'api-management',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    ...master,
    ...page,
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/privacy-policy',
      name: 'publicPrivacyPolicy',
      component: () => import('@/views/public/PrivacyPolicy.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register/:companyId',
      name: 'publicRegister',
      component: () => import('@/views/public/Register'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register/success',
      name: 'publicRegisterSuccess',
      component: () => import('@/views/public/RegisterSuccess'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/verify/work-permit',
      name: 'publicVerifyWorkPermit',
      component: () => import('@/views/public/WorkView'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/verify/training',
      name: 'publicVerifyTraining',
      component: () => import('@/views/public/UserView'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/data-not-found',
      name: 'publicDataNotFound',
      component: () => import('@/views/error/DataNotFound'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const expectAuth = ['login', 'page-forgot-password', 'error-404', 'error-500', 'not-authorized', 'publicRegister', 'publicRegisterSuccess', 'publicVerifyWorkPermit', 'publicVerifyTraining', 'publicDataNotFound', 'publicPrivacyPolicy']
  const {rule} = to.meta
  let menuScope = localStorage.getItem('scope')
  menuScope = menuScope ? JSON.parse(menuScope) : []

  // window.location.href=`https://safetyapp.ttlsystem.com${to.fullPath}`

  if (from.name === 'login') {
    menuScope = localStorage.getItem('scope')
    menuScope = menuScope ? JSON.parse(menuScope) : []
  }

  if(menuScope.length === 0) {
    // if(to.name !== 'login' && to.name !== 'page-not-authorized') {
    if(!expectAuth.includes(to.name)) {
      return next({name: 'login'})
    }
  }

  if (!expectAuth.includes(to.name)) {
    if (rule) {
      if (menuScope.length > 0 && !menuScope.includes(rule) && rule !== 'home') {
        return next({name: 'page-not-authorized'})
      }
    }
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
