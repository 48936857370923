import template from './template'
import department from './department'
import location from './location'
import position from './position'
import attachmentType from './attachmentType'
import company from './company'
import question from './question'
import compliance from './compliance'
import workType from './workType'

const data = [
  ...template,
  ...department,
  ...location,
  ...position,
  ...attachmentType,
  ...company,
  ...question,
  ...compliance,
  ...workType,
]

export default data
