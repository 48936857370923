export default [
  {
    path: '/offline-training',
    name: 'pageOfflineTraining',
    component: () => import('@/views/page/OfflineTrainingList'),
    meta: {
      pageTitle: 'Offline Training',
      breadcrumb: [
        {
          text: 'Offline Training',
          active: true,
        },
      ],
    },
  },
  {
    path: '/offline-training/add',
    name: 'pageOfflineTrainingAdd',
    component: () => import('@/views/page/OfflineTrainingForm'),
    meta: {
      pageTitle: 'Offline Training',
      navActiveLink: 'pageOfflineTraining',
      breadcrumb: [
        {
          text: 'Offline Training',
          to: '/offline-training'
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      if (from.path === '/') next({ name: 'home' })
      else next()
    }
  },
  {
    path: '/offline-training/edit/:id',
    name: 'pageOfflineTrainingEdit',
    component: () => import('@/views/page/OfflineTrainingForm'),
    meta: {
      pageTitle: 'Offline Training',
      navActiveLink: 'pageOfflineTraining',
      breadcrumb: [
        {
          text: 'Offline Training',
          to: '/offline-training'
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      if (from.path === '/') next({ name: 'home' })
      else next()
    }
  },
]