export default [
  {
    path: '/master/location',
    name: 'pageMasterLocation',
    component: () => import('@/views/master/LocationList'),
    meta: {
      pageTitle: 'Location',
      breadcrumb: [
        {
          text: 'Location',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/location/add',
    name: 'pageMasterLocationAdd',
    component: () => import('@/views/master/LocationForm'),
    meta: {
      pageTitle: 'Location',
      navActiveLink: 'pageMasterLocation',
      breadcrumb: [
        {
          text: 'Location',
          to: '/master/location'
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/location/edit/:id',
    name: 'pageMasterLocationEdit',
    component: () => import('@/views/master/LocationForm'),
    meta: {
      pageTitle: 'Location',
      navActiveLink: 'pageMasterLocation',
      breadcrumb: [
        {
          text: 'Location',
          to: '/master/location'
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      if (from.path === '/') next({ name: 'home' })
      else next()
    }
  },
]