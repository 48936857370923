export default [
  {
    path: '/profile/edit',
    name: 'pageProfileEdit',
    component: () => import('@/views/page/ProfileEdit'),
    meta: {
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Edit profile',
          active: true,
        },
      ],
    },
  },
  {
    path: '/profile/change-password',
    name: 'pageProfileChangePassword',
    component: () => import('@/views/page/ProfileChangePassword'),
    meta: {
      pageTitle: 'Profile',
      breadcrumb: [
        {
          text: 'Change password',
          active: true,
        },
      ],
    },
  },
]