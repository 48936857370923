export default [
  {
    path: '/master/department',
    name: 'pageMasterDepartment',
    component: () => import('@/views/master/DepartmentList'),
    meta: {
      pageTitle: 'Department',
      breadcrumb: [
        {
          text: 'Department',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/department/add',
    name: 'pageMasterDepartmentAdd',
    component: () => import('@/views/master/DepartmentForm'),
    meta: {
      pageTitle: 'Department',
      navActiveLink: 'pageMasterDepartment',
      breadcrumb: [
        {
          text: 'Department',
          to: '/master/department'
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/department/edit/:id',
    name: 'pageMasterDepartmentEdit',
    component: () => import('@/views/master/DepartmentForm'),
    meta: {
      pageTitle: 'Department',
      navActiveLink: 'pageMasterDepartment',
      breadcrumb: [
        {
          text: 'Department',
          to: '/master/department'
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      if (from.path === '/') next({ name: 'home' })
      else next()
    }
  },
]