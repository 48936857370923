
export default {
  namespaced: true,
  state: {
    examResult: {},
  },
  getters: {},
  mutations: {
    setExamResult(state, val) {
      state.examResult = val
    },
  },
  actions: {

  },
}
