export default [
  {
    path: '/master/question',
    name: 'pageMasterQuestion',
    component: () => import('@/views/master/QuestionList'),
    meta: {
      pageTitle: 'Question',
      breadcrumb: [
        {
          text: 'Question',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/question/add',
    name: 'pageMasterQuestionAdd',
    component: () => import('@/views/master/QuestionForm'),
    meta: {
      pageTitle: 'Question',
      navActiveLink: 'pageMasterQuestion',
      breadcrumb: [
        {
          text: 'Question',
          to: '/master/question'
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/question/edit/:id',
    name: 'pageMasterQuestionEdit',
    component: () => import('@/views/master/QuestionForm'),
    meta: {
      pageTitle: 'Question',
      navActiveLink: 'pageMasterQuestion',
      breadcrumb: [
        {
          text: 'Question',
          to: '/master/question'
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      if (from.path === '/') next({ name: 'home' })
      else next()
    }
  },
]