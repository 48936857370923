export default {
  namespaced: true,
  state: {
    workTypeLists: [],
    step1: {},
    createSuccess: {}
  },
  getters: {
    getWorkTypeDetail: state => value => {
      return state.workTypeLists.filter(data => value.includes(data.workTypeId))
    }
  },
  mutations: {
    setWorkType(state, val) {
      state.workTypeLists = val
    },
    setStep1(state, val) {
      state.step1 = val
    },
    setCreateSuccess(state, val) {
      state.createSuccess = val
    },
  },
  actions: {

  },
}
