<template>
  <div class="mb-1">
    <div
        v-if="getFileExtension === 'file'"
        @click="downloadFile"
        class="thumbnail text-center bg-primary  colors-container rounded text-white width-150 height-150 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow">
      <div class="font-small-3">
        <div>
          <feather-icon icon="FileTextIcon" size="60"/>
        </div>
        {{ description }}
      </div>
    </div>
    <silent-box class="thumbnail" v-else-if="getFileExtension === 'image'" :gallery="[{src: src}]" />
  </div>
</template>

<script>
export default {
  name: "ThumbnailFile",
  props: {
    src: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false
    }
  },
  computed: {
    getFileExtension() {
      if(this.src.substring(0, 10) === 'data:image') {
        return 'image'
      } else {
        if(this.src) {
          let splitData = this.src.split('.')
          let ext = splitData[(splitData.length - 1)].toLowerCase()
          let allowed = ['jpg', 'jpeg', 'gif', 'png']
          return allowed.includes(ext) ? 'image' : 'file'
        } else {
          return 'none'
        }
      }
    }
  },
  methods: {
    downloadFile() {
      // window.open(this.src, '_blank')
      window.location.href=this.src
    }
  }
}
</script>

<style lang="scss" scoped>
.thumbnail {
  transition: all 0.2s ease-in-out;
  &:hover {
    cursor: pointer;
    //transform: translateY(-4px) scale(1.1);
    z-index: 1000;
  }
  img {
    margin-top: 1.28rem;
  }
}
</style>