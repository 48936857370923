export default [
  {
    path: '/master/work-type',
    name: 'pageMasterWorkType',
    component: () => import('@/views/master/WorkTypeList'),
    meta: {
      pageTitle: 'WorkType',
      breadcrumb: [
        {
          text: 'Work Type',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/work-type/add',
    name: 'pageMasterWorkTypeAdd',
    component: () => import('@/views/master/WorkTypeForm'),
    meta: {
      pageTitle: 'WorkType',
      navActiveLink: 'pageMasterWorkType',
      breadcrumb: [
        {
          text: 'Work Type',
          to: '/master/work-type'
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/work-type/edit/:id',
    name: 'pageMasterWorkTypeEdit',
    component: () => import('@/views/master/WorkTypeForm'),
    meta: {
      pageTitle: 'Work Type',
      navActiveLink: 'pageMasterWorkType',
      breadcrumb: [
        {
          text: 'WorkType',
          to: '/master/work-type'
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      if (from.path === '/') next({ name: 'home' })
      else next()
    }
  },
]