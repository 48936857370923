export default [
  {
    path: '/report/work-permit',
    name: 'reportWorkPermit',
    component: () => import('@/views/report/WorkPermitReport'),
    meta: {
      pageTitle: 'Work Permit Report',
      breadcrumb: [
        {
          text: 'Work Permit Report',
          active: true,
        },
      ],
    },
  },
]