export default [
  {
    path: '/work-permit',
    name: 'pageWork',
    component: () => import('@/views/page/WorkList'),
    meta: {
      pageTitle: 'Work Permit',
      breadcrumb: [
        {
          text: 'Work Permit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/work-permit/add/step/1',
    name: 'pageWorkAddStep1',
    component: () => import('@/views/page/WorkFormAddStep1'),
    meta: {
      pageTitle: 'Work Permit',
      navActiveLink: 'pageWork',
      breadcrumb: [
        {
          text: 'Work Permit',
          to: '/work-permit'
        },
        {
          text: 'Add Step 1',
          active: true,
        },
      ],
    },
  },
  {
    path: '/work-permit/add/step/2',
    name: 'pageWorkAddStep2',
    component: () => import('@/views/page/WorkFormAddStep2'),
    meta: {
      pageTitle: 'Work Permit',
      navActiveLink: 'pageWork',
      breadcrumb: [
        {
          text: 'Work Permit',
          to: '/work-permit'
        },
        {
          text: 'Add Step 2',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      if (from.path === '/') next({name: 'pageWork'})
      else next()
    }
  },
  {
    path: '/work-permit/:id/edit/step/1',
    name: 'pageWorkEditStep1',
    component: () => import('@/views/page/WorkFormAddStep1'),
    meta: {
      pageTitle: 'Work Permit',
      navActiveLink: 'pageWork',
      breadcrumb: [
        {
          text: 'Work Permit',
          to: '/work-permit'
        },
        {
          text: 'Edit Step 1',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      if (from.path === '/') next({name: 'pageWork'})
      else next()
    }
  },
  {
    path: '/work-permit/:id/edit/step/2',
    name: 'pageWorkEditStep2',
    component: () => import('@/views/page/WorkFormAddStep2'),
    meta: {
      pageTitle: 'Work Permit',
      navActiveLink: 'pageWork',
      breadcrumb: [
        {
          text: 'Work Permit',
          to: '/work-permit'
        },
        {
          text: 'Edit Step 2',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      if (from.path === '/') next({name: 'pageWork'})
      else next()
    }
  },
  {
    path: '/work-permit/add/success',
    name: 'pageWorkAddSuccess',
    component: () => import('@/views/page/WorkFormAddSuccess'),
    meta: {
      pageTitle: 'Work Permit',
      navActiveLink: 'pageWork',
      breadcrumb: [
        {
          text: 'Work Permit',
          to: '/work-permit'
        },
        {
          text: 'Success',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      if (from.path === '/') next({name: 'pageWork'})
      else next()
    }
  },
  {
    path: '/work-permit/:id/view',
    name: 'pageWorkView',
    component: () => import('@/views/page/WorkFormView'),
    meta: {
      pageTitle: 'Work Permit',
      navActiveLink: 'pageWork',
      breadcrumb: [
        {
          text: 'Work Permit',
          to: '/work-permit'
        },
        {
          text: 'View',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      if (from.path === '/') next({name: 'pageWork'})
      else next()
    }
  },
  {
    path: '/work-permit/:id/approval',
    name: 'pageWorkApproval',
    component: () => import('@/views/page/WorkFormApprove'),
    meta: {
      pageTitle: 'Work Permit',
      navActiveLink: 'pageWork',
      breadcrumb: [
        {
          text: 'Work Permit',
          to: '/work-permit'
        },
        {
          text: 'Approval',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      if (from.path === '/') next({name: 'pageWork'})
      else next()
    }
  },
  {
    path: '/work-permit/:id/operation',
    name: 'pageWorkOperation',
    component: () => import('@/views/page/WorkFormOperation'),
    meta: {
      pageTitle: 'Work Permit',
      navActiveLink: 'pageWork',
      breadcrumb: [
        {
          text: 'Work Permit',
          to: '/work-permit'
        },
        {
          text: 'Operation',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      if (from.path === '/') next({name: 'pageWork'})
      else next()
    }
  },
  {
    path: '/work-permit/:id/safety-check',
    name: 'pageWorkSafetyCheck',
    component: () => import('@/views/page/WorkFormSafetyCheck'),
    meta: {
      pageTitle: 'Work Permit',
      navActiveLink: 'pageWork',
      breadcrumb: [
        {
          text: 'Work Permit',
          to: '/work-permit'
        },
        {
          text: 'Operation',
          to: {
            name: 'pageWorkOperation',
            params: {
              id: ''
            }
          }
        },
        {
          text: 'Safety Check',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      if (from.path === '/') next({name: 'pageWork'})
      else next()
    }
  },
  {
    path: '/work-permit/:id/safety-cross-check',
    name: 'pageWorkSafetyCrossCheck',
    component: () => import('@/views/page/WorkFormSafetyCheck'),
    meta: {
      pageTitle: 'Work Permit',
      navActiveLink: 'pageWork',
      breadcrumb: [
        {
          text: 'Work Permit',
          to: '/work-permit'
        },
        {
          text: 'Operation',
          to: {
            name: 'pageWorkOperation',
            params: {
              id: ''
            }
          }
        },
        {
          text: 'Safety Cross Check',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      if (from.path === '/') next({name: 'pageWork'})
      else next()
    }
  },
  {
    path: '/work-permit/:id/extend',
    name: 'pageWorkExtend',
    component: () => import('@/views/page/WorkFormExtend'),
    meta: {
      pageTitle: 'Work Permit',
      navActiveLink: 'pageWork',
      breadcrumb: [
        {
          text: 'Work Permit',
          to: {
            name: 'pageWork',
          }
        },
        {
          text: 'Operation',
          to: {
            name: 'pageWorkOperation',
            params: {
              id: ''
            }
          }
        },
        {
          text: 'Extend',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      if (from.path === '/') next({name: 'pageWork'})
      else next()
    }
  },
  {
    path: '/work-permit/:id/finish',
    name: 'pageWorkFinish',
    component: () => import('@/views/page/WorkFormFinish'),
    meta: {
      pageTitle: 'Work Permit',
      navActiveLink: 'pageWork',
      breadcrumb: [
        {
          text: 'Work Permit',
          to: '/work-permit'
        },
        {
          text: 'Operation',
          to: {
            name: 'pageWorkOperation',
            params: {
              id: ''
            }
          }
        },
        {
          text: 'Finish',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      if (from.path === '/') next({name: 'pageWork'})
      else next()
    }
  },
]