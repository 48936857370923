import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    toastSuccess: {},
    toastError: {},
    datePickerConfig: {
      dateFormat: 'd/m/Y',
      time_24hr: true
    },
    timePickerConfig: {
      dateFormat: 'H:i',
      time_24hr: true,
      enableTime: true,
      noCalendar: true,
      defaultHour: 8
    }
  },
  getters: {},
  mutations: {
    setToastSuccess(state, val) {
      state.toastSuccess = val
    },
    setToastError(state, val) {
      state.toastError = val
    },
    clearToast(state, val) {
      state.toastSuccess = val
      state.toastError = val
    },
  },
  actions: {

  },
}
