export default [
  {
    path: '/master/company',
    name: 'pageMasterCompany',
    component: () => import('@/views/master/CompanyList'),
    meta: {
      pageTitle: 'Company',
      breadcrumb: [
        {
          text: 'Company',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/company/add',
    name: 'pageMasterCompanyAdd',
    component: () => import('@/views/master/CompanyForm'),
    meta: {
      pageTitle: 'Company',
      navActiveLink: 'pageMasterCompany',
      breadcrumb: [
        {
          text: 'Company',
          to: '/master/company'
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/company/edit/:id',
    name: 'pageMasterCompanyEdit',
    component: () => import('@/views/master/CompanyForm'),
    meta: {
      pageTitle: 'Company',
      navActiveLink: 'pageMasterCompany',
      breadcrumb: [
        {
          text: 'Company',
          to: '/master/company'
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      if (from.path === '/') next({ name: 'home' })
      else next()
    }
  },
]