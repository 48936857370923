export default [
  {
    path: '/master/position',
    name: 'pageMasterPosition',
    component: () => import('@/views/master/PositionList'),
    meta: {
      pageTitle: 'Position',
      breadcrumb: [
        {
          text: 'Position',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/position/add',
    name: 'pageMasterPositionAdd',
    component: () => import('@/views/master/PositionForm'),
    meta: {
      pageTitle: 'Position',
      navActiveLink: 'pageMasterPosition',
      breadcrumb: [
        {
          text: 'Position',
          to: '/master/position'
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/position/edit/:id',
    name: 'pageMasterPositionEdit',
    component: () => import('@/views/master/PositionForm'),
    meta: {
      pageTitle: 'Position',
      navActiveLink: 'pageMasterPosition',
      breadcrumb: [
        {
          text: 'Position',
          to: '/master/position'
        },
        {
          text: 'Edit',
          active: true,
        },
      ]
    },
    beforeEnter: (to, from, next) => {
      if (from.path === '/') next({ name: 'home' })
      else next()
    }
  },
]