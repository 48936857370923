export default [
  {
    path: '/user',
    name: 'pageUser',
    component: () => import('@/views/page/UserList'),
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'User',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user/add',
    name: 'pageUserAdd',
    component: () => import('@/views/page/UserForm'),
    meta: {
      pageTitle: 'User',
      navActiveLink: 'pageUser',
      breadcrumb: [
        {
          text: 'User',
          to: '/user'
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      if (from.path === '/') next({ name: 'home' })
      else next()
    }
  },
  {
    path: '/user/edit/:id',
    name: 'pageUserEdit',
    component: () => import('@/views/page/UserForm'),
    meta: {
      pageTitle: 'User',
      navActiveLink: 'pageUser',
      breadcrumb: [
        {
          text: 'User',
          to: '/user'
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      if (from.path === '/') next({ name: 'home' })
      else next()
    }
  },
  {
    path: '/user/view/:id',
    name: 'pageUserView',
    component: () => import('@/views/page/UserForm'),
    meta: {
      pageTitle: 'User',
      navActiveLink: 'pageUser',
      breadcrumb: [
        {
          text: 'User',
          to: '/user'
        },
        {
          text: 'View',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      if (from.path === '/') next({ name: 'home' })
      else next()
    }
  },
]