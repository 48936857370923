export default [
  {
    path: '/training',
    name: 'pageTraining',
    component: () => import('@/views/page/TrainingList'),
    meta: {
      pageTitle: 'Training',
      breadcrumb: [
        {
          text: 'Training',
          active: true,
        },
      ],
    },
  },
  {
    path: '/training/exam',
    name: 'pageTrainingExam',
    component: () => import('@/views/page/TrainingExam'),
    meta: {
      pageTitle: 'Training',
      navActiveLink: 'pageTraining',
      breadcrumb: [
        {
          text: 'Training',
          to: '/training'
        },
        {
          text: 'Exam',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      if (from.path === '/') next({ name: 'pageTraining' })
      else next()
    }
  },
  {
    path: '/training/result',
    name: 'pageTrainingResult',
    component: () => import('@/views/page/TrainingResult'),
    meta: {
      pageTitle: 'Training',
      navActiveLink: 'pageTraining',
      breadcrumb: [
        {
          text: 'Training',
          to: '/training'
        },
        {
          text: 'Result',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      if (from.path === '/') next({ name: 'pageTraining' })
      else next()
    }
  },
]