import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ThumbnailFile from "@/components/ThumbnailFile"

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import '@/libs/vue-select'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('vSelect', vSelect)
Vue.component('flatPickr', flatPickr)
Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)
Vue.component('ToastificationContent', ToastificationContent)
Vue.component('ThumbnailFile', ThumbnailFile)
