export default [
  {
    path: '/master/template',
    name: 'pageMasterTemplate',
    component: () => import('@/views/master/TemplateList'),
    meta: {
      pageTitle: 'Template',
      breadcrumb: [
        {
          text: 'Template',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/template/add',
    name: 'pageMasterTemplateAdd',
    component: () => import('@/views/master/TemplateForm'),
    meta: {
      pageTitle: 'Template',
      navActiveLink: 'pageMasterTemplate',
      breadcrumb: [
        {
          text: 'Template',
          to: '/master/template'
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/template/edit/:id',
    name: 'pageMasterTemplateEdit',
    component: () => import('@/views/master/TemplateForm'),
    meta: {
      pageTitle: 'Template',
      navActiveLink: 'pageMasterTemplate',
      breadcrumb: [
        {
          text: 'Template',
          to: '/master/template'
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
    beforeEnter: (to, from, next) => {
      if (from.path === '/') next({ name: 'home' })
      else next()
    }
  },
]