import user from './user'
import training from './training'
import work from './work'
import profile from './profile'
import offlineTraining from './offlineTraining'
import report from './report'

const data = [
  ...user,
  ...training,
  ...work,
  ...profile,
  ...offlineTraining,
  ...report,
]

export default data
